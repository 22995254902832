<template>
	<div class="el-container">
		<el-form :model="form" ref="form">
			<el-select v-model="fCommerceTypeID" :filterable="true" :default-first-option="true" placeholder="类型" @change="selectChanged">
				<el-option v-for="(item, index) in itemObj" :label="item.fCommerceType" :value="item.fCommerceTypeID" :key="index"></el-option>
			</el-select>
			<el-form-item label="企业名称" :label-width="formLabelWidth"><el-input v-model="form.fCustomerName" autocomplete="off"></el-input></el-form-item>
			<el-form-item label="税号" :label-width="formLabelWidth"><el-input v-model="form.fInvoiceClassID" autocomplete="off"></el-input></el-form-item>
			<el-form-item label="开户行" :label-width="formLabelWidth"><el-input v-model="form.fGovernmenAreaID" autocomplete="off"></el-input></el-form-item>
			<el-form-item label="账号" :label-width="formLabelWidth"><el-input v-model="form.fInvoiceClassID" autocomplete="off"></el-input></el-form-item>
			<el-form-item label="地址" :label-width="formLabelWidth"><el-input v-model="form.fAddress" autocomplete="off"></el-input></el-form-item>
			<el-form-item label="电话" :label-width="formLabelWidth"><el-input v-model="form.fPhone" autocomplete="off"></el-input></el-form-item>
			<el-form-item label="增值税专用发票" :label-width="formLabelWidth"><el-input v-model="form.fWalletPassWord" autocomplete="off"></el-input></el-form-item>

			<!-- 	<el-form-item label="营业执照五证合一(可选择)：">
				<div class="form-box-in"><UploadImgList class="uploadimglist" uploadDir="Web/SupplyDemand" value="srclist1" v-model="imglist1" uploadType="image" /></div>
			</el-form-item>
			<el-form-item label="委托授权书">
				<div class="form-box-in"><UploadImgList class="uploadimglist" uploadDir="Web/SupplyDemand" value="srclist2" v-model="imglist2" uploadType="image" /></div>
			</el-form-item>
			<el-form-item label="一般纳税人资格证明原件(...)">
				<div class="form-box-in"><UploadImgList class="uploadimglist" uploadDir="Web/SupplyDemand" value="srclist3" v-model="imglist3" uploadType="image" /></div>
			</el-form-item>
 -->
			<div v-for="(item, index) in imgObj" :key="index">
				<el-form-item :label="item.rule.fRuleName">
					<span v-model="fRuleID[item.rule.fRuleID]"></span>
					<div class="form-box-in"  >
						<UploadImgList
							ref="loadImgList"
							:rules="item.rule.fFileType"
							:token="item.token"
							class="uploadimglist"
							uploadDir="Web/SupplyDemand"
							value="srclist"
							:rulesId="item.rule.fRuleID"
							v-model="list[index]"
							uploadType="image"
						/>
					</div>
				</el-form-item>
			</div>

			<div>
				<span slot="footer" class="dialog-footer"><el-button type="primary" @click="sub">确 定</el-button></span>
			</div>
		</el-form>
	</div>
</template>

<script>
import UploadImgList from '@/components/upload/UploadImgList';
export default {
	name: 'test',
	components: {
		UploadImgList
	},
	data() {
		return {
			form: {},
			imglist1: [],
			imglist2: [],
			imglist3: [],
			srclist1: [],
			srclist2: [],
			srclist3: [],
			formLabelWidth: '120',
			itemObj: [],
			fCommerceTypeID: '',
			imgObj: [],
			list:[],
			srclist: [],
			fRuleID:[]
		};
	},
	mounted() {
		this.getType();
	},
	methods: {
		sub() {
			console.log('11111111111111',this.fRuleID);
			let newArr= [];			
			for(let item of this.list){
				for(let i of  item){
						newArr.push({
							"fFileName": i.url,
							"fPath": i.url,
							"ruleId":123
						 });
				}							
			}					
			this.ApiRequestPost('/api/mall/ebcustomer/adjust-bill/create', {
				 "files":newArr,
				 "fBillTypeID": 0,
				  "fCustomerName": "string",
				  "fCommerceTypeID": 0,
				  "fBusinessID": 0,
				  "fGovernmenAreaID": 0,
				  "fIdentityCode": "string",
				  "fInvoiceClassID": 0,
				  "fPhone": "string",
				  "fAddress": "string",
				  "fRemark": "string",
				  "fWalletPassWord": "string",
				  "fAppTypeID": 0,
				  "fInviterID": 0
				
			}).then(
				result => {
					 console.log(result);
				}
				// rej => {}
			);			
		},
		getType() {
			this.ApiRequestPost('/api/mall/ebbase/commerce-type/get-list', {}).then(
				result => {
					console.log(result);
					this.itemObj = [];
					this.itemObj = result.obj.items;
				}
				// rej => {}
			);
		},
		selectChanged() {
			this.getTypeRules();
		},
		getTypeRules() {
			this.ApiRequestPost('/api/mall/ebcustomer/adjust-bill/get-adjust-bill-type-annex-rule', {
				id: this.fCommerceTypeID
			}).then(
				result => {
					console.log(result);
					this.imgObj = [];
					this.imgObj = result;
					// localStorage.setItem('',)
				}
				// rej => {}
			);
		},
		create() {}
	}
};
</script>

<style>
.uploadimglist {
	border: 1px solid #d0ebee;
	width: 486px;
	border-radius: 4px;
}
.el-container {
	padding: 20px 0;
	width: 1225px;
	margin: 0 auto;
}
</style>
